import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Media = ({ location }) => {
  return (
    <>
      <Layout location={location}>
        <Seo title="NuQ Media" />
        <section
          className="page-content nuq-scores-wrapper"
          style={{ paddingBottom: "6rem" }}
        >
          <article>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className="text-center">
                    <h2
                      className="section-title text-d-cyan text-center mb-0"
                      style={{ fontSize: "45px" }}
                    >
                      NuQ Kitchen
                    </h2>
                  </div>
                  <div>
                    <iframe
                      src="https://drive.google.com/file/d/1Vr2Q3vsPZlQQlv-3isDmcFKhdvA6g36Y/preview"
                      width="100%"
                      height="280"
                      title="Fvideo"
                      allowFullscreen
                      className="border-0"
                    ></iframe>
                  </div>
                  <div>
                    <p style={{ fontSize: "20px" }}>
                      Throughout history humans have created countless recipes
                      with various ingredients and flavors to suit all tastes;
                      however, none of those recipe ingredients provide a
                      detailed measure of their nutrition density, until now!
                    </p>
                    <p style={{ fontSize: "20px" }}>
                      With <b>NuQ Kitchen</b> we teach consumers how Cooking
                      with Nutrients enriches the nutrient density of the
                      prepared meals that are both delish and, more importantly,
                      nutrish! We call it Cooking Infotainment and we hope you
                      will watch and implement our methods. Your comments and
                      suggestions are most welcome.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="text-center">
                    <h2
                      className="section-title text-d-cyan text-center mb-0"
                      style={{ fontSize: "45px" }}
                    >
                      Explainer Videos
                    </h2>
                  </div>
                  <div>
                    <iframe
                      src="https://www.youtube.com/embed/j82oa4Xmys0?rel=0"
                      width="100%"
                      height="280"
                      title="What Are Nutrients"
                      allowFullscreen
                      className="border-0"
                    ></iframe>
                  </div>
                  <div>
                    <p style={{ fontSize: "20px" }}>
                      Part of our strategy is to create numerous animated explainer videos (30-60secs) that detail how various nutrients traverse the body, get absorbed by various organs and the benefits they provide for optimal health.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </Layout>
    </>
  )
}

export default Media
